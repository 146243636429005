import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Typography,
  Grid,
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Tooltip,
  Radio,
  Checkbox,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import DefaultLayout from "../Layout/DefaultLayout";
import BannerDisplayer from "../Banner/BannerDisplayer";
import ProfileImageDisplayer from "../Profile/ProfileImageDisplayer";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import ReactHtmlParser from "html-react-parser";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import AccessDisplayRow from "./AccessDisplayRow";
import DeleteIcon from "../Commons/Icons/DeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import EmojiObject from '@mui/icons-material/EmojiObjectsOutlined';
import { fetchContacts } from "../../store/Actions/contactAction";
import { getCurrentUser } from "../../store/Actions/userAction";

const EditContact = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;
  const country = useRef("Magyarország");
  const contactTypes = useSelector((state) => state.contact.contactTypes);

  const [change, setChange] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);

  const [phone, setPhone] = useState("+36");
  const [phoneBlur, setPhoneBlur] = useState(false);
  const chid = localStorage.getItem("chid");
  const dispatch = useDispatch();
  const { items: contacts = null } = useSelector((state) => state.contact);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [hasLifeSignalContact, setHasLifeSignalContact] = useState(false);

  useEffect(() => {
    if ((contacts === null || contacts.length === 0) && !contactsLoaded) {
      dispatch(fetchContacts());
      setContactsLoaded(true);
    } else {
      const hasOnDeathBeneficiary = contacts?.some(
        (contact) =>
          contact.accessTypeAssets === "onDeath" ||
          contact.accessTypeDiaryItems === "onDeath" ||
          contact.accessTypePostMortemRequests === "onDeath"
      );
      setHasLifeSignalContact(hasOnDeathBeneficiary);
    }

  }, [contacts, dispatch]);

  const [contactData, setContactData] = useState({
    id: null,
    category: "",
    draft: true,
    dateOfBirth: "",
    email: "",
    firstName: "",
    lastName: "",
    relationType: "",
    customRelationType: "",
    phone: "+36",
    message: "",
    comment: null,
    accessTypeAssets: null,
    accessAtAssets: "",
    accessTypeDiaryItems: null,
    accessAtDiaryItems: "",
    accessTypePostMortemRequests: null,
    accessAtPostMortemRequests: "",
  });

  const [contactDataOri, setContactDataOri] = useState({
    id: null,
    category: "",
    draft: true,
    dateOfBirth: "",
    email: "",
    firstName: "",
    lastName: "",
    relationType: "",
    customRelationType: "",
    phone: "+36",
    message: "",
    comment: null,
    accessTypeAssets: null,
    accessAtAssets: "",
    accessTypeDiaryItems: null,
    accessAtDiaryItems: "",
    accessTypePostMortemRequests: null,
    accessAtPostMortemRequests: "",
  });

  const [editMode, setEditMode] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [lifeSignal, setLifeSignal] = useState(null);
  const [isLifeSignalEnabled, setIsLifeSignalEnabled] = useState(false);
  const [isSaveLifeSignal, setIsSaveLifeSignal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    watch,
    reset,
    trigger,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const email = watch("email");
  const relationType = watch("relationType");

  useEffect(() => {
    checkRequiredFields();
  }, [isValid, firstName, lastName, relationType, email, phone]);

  const checkRequiredFields = () => {
    //console.log(errors);
    //console.log("isvalid", isValid);
    //console.log("phoneIsValid", phoneIsValid);
    //console.log("firstName", firstName);
    //console.log("lastName", lastName);
    //console.log("relationType", relationType);
    //console.log("email", email);
    //console.log("phone", phone);

    if (
      //isValid &&
      firstName &&
      lastName &&
      relationType &&
      email &&
      phone
    ) {
      console.log('the phone length is ', phone.replace(/\s/g, "")?.length);
      if(phone.replace(/\s/g, "")?.length > 5 && matchIsValidTel(phone.replace(/\s/g, "")) && isValid) {
        setIsSaveEnabled(true);
      } else if(phone.replace(/\s/g, "")?.length <= 5) {
        setIsSaveEnabled(true);
      }
    } else {
      setIsSaveEnabled(false);
    }
  };

  const displayPhoneValidation = (phoneNumber) => {
    let number = phoneNumber.replace(/\s/g, "");
    //return number.length > 8 && !matchIsValidTel(number);
    return !matchIsValidTel(number);
  };

  const onSubmit = (data, e) => {
    if (contactData.id) {
      doSaveContact(e, collectData(data));
    } else {
      //console.log("State: ", state);
      doSaveContact(e, collectData(data));
    }
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const handleLifeSignalChange = (ev) => {
    setLifeSignal(ev.target.value);
    setIsSaveLifeSignal(true);
  };

  const doSaveContact = (event, data) => {
    //console.log("doSaveContact", data);
    event.stopPropagation();
    //remove status field
    delete data.contact.status;

    if (contactData.id) {
      //      setGlobalEvent({ type: "doSaveContact", data });
      if (modalFlag && data.contact.accessTypeAssets != null) {
        setGlobalEvent({
          type: "showConfirm",
          data: {
            callroot: "editContact",
            calldata: data,
            title: "",
            description: t("contact.confirmquestion"),
          },
        });
      } else {
        setGlobalEvent({ type: "doSaveContact", data });
      }
    } else {
      setContactData(data.contact);
      if (data.contact.accessTypeAssets !== null) {
        setGlobalEvent({
          type: "showConfirm",
          data: {
            callroot: "editContact",
            calldata: data,
            title: "",
            description: t("contact.confirmquestion"),
          },
        });
      } else {
        setGlobalEvent({ type: "doSaveContact", data });
      }
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    setGlobalEvent({
      type: "doSaveContact",
      data: collectData({
        email: contactData.email,
        firstName: contactData.firstName,
        lastName: contactData.lastName,
        message: contactData.message,
        comment: contactData.comment != "" ? contactData.comment : null,
        customRelationType: contactData.customRelationType,
      }),
    });
  };

  const collectData = (data) => {
    return {
      contact: {
        ...contactData,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: phone?.replace(/\s/g, ""),
        message: data.message,
        comment: contactData.comment != "" ? contactData.comment : null,
        customRelationType: data.customRelationType,
      },
    };
  };

  useEffect(() => {
    //console.log("State: ", state);
    if (!state.data.accessTypeAssets) state.data.accessTypeAssets = null;
    if (!state.data.accessTypeDiaryItems)
      state.data.accessTypeDiaryItems = null;
    if (!state.data.accessTypePostMortemRequests)
      state.data.accessTypePostMortemRequests = null;
    setModalFlag(state.data.accessTypeAssets == null);
    setContactDataOri(state.data);
    setContactData(state.data);
    if (state.data?.phone) setPhone(state.data?.phone);
    setEditMode(state?.editmode || false);
  }, [state]);

  useEffect(() => {
    //console.log(globalEvent);

    if (
      globalEvent?.type === "confirmAnswered" &&
      globalEvent?.data.callroot === "editContact" &&
      globalEvent?.data.answer
    ) {
      setGlobalEvent({
        type: "doSaveContact",
        data: globalEvent?.data.calldata,
      });
    }

    if (globalEvent?.type == "responseSaveContact") {
      setGlobalEvent({
        type: "doSaveLifeSignal",
        data: {
          user: { lifeSignal: lifeSignal },
        },
      });
    }

    if (globalEvent?.type == "responseSaveLifeSignal") {
      setGlobalEvent({
        type: "doGetUser",
        data: {
          user: { lifeSignal: lifeSignal },
        },
      });
    }

    if (globalEvent?.type == "responseSaveLifeSignalError") {
      setGlobalEvent({
        type: "doGetUser",
        data: {
          user: { lifeSignal: lifeSignal },
        },
      });
    }

    if (globalEvent?.type == "responseGetUser") {
      setUser(globalEvent?.data.user);
      setIsSaveLifeSignal(false);

      if (globalEvent?.data.user.lifeSignal) {
        setLifeSignal(globalEvent?.data.user.lifeSignal);
        setIsLifeSignalEnabled(false);
      } else {
        setIsLifeSignalEnabled(true);
      }
    }
  }, [globalEvent]);

  useEffect(() => {
    //setPhoneIsValid(matchIsValidTel(phone));
    if (contactData.phone) {
      setPhone(contactData.phone);
      setPhoneIsValid(matchIsValidTel(contactData.phone.replace(/\s/g, "")));
    }
    setValue("firstName", contactData.firstName);
    setValue("lastName", contactData.lastName);
    setValue("email", contactData.email);
    setValue("message", contactData.message);
    setValue("comment", contactData.comment);
    setValue("relationType", contactData.relationType);
    setValue("customRelationType", contactData.customRelationType);

    if (contactData?.id) {
      setTimeout(() => {
        trigger();
        checkRequiredFields();
      }, 500);
    }
  }, [contactData, setValue]);

  return (
    <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
      <BannerDisplayer position="top" page="contact_list" />
      {!editMode ? (
        <Box sx={{ display: "flex", justifyContent: { xl: "center" } }}>
          <Grid
            container
            sx={{
              mt: { xs: 0, sm: 8 },
              pl: { xs: 2, md: 27 },
              pr: { xs: 2, md: 17 },
              mb: 20,
              width: { xl: "1530px" },
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
            <Grid item xs={12} md={7}>
              <Grid container sx={{ width: { xs: "auto", sm: "100%" } }}>
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ mb: 6, pl: { xs: 2, sm: 0 } }}
                  >
                    <Button
                      onClick={() => navigate("/contact-list")}
                      variant="text"
                      sx={{
                        height: "50px",
                        width: "50px !important",
                        minWidth: "50px",
                        p: 0,
                        boxShadow: "0px 0px 10px 0px #0000001A",
                        borderRadius: "32px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.19697 14.5546L8.4042 15.3477C8.06852 15.6834 7.52573 15.6834 7.19362 15.3477L0.251538 8.40706C-0.0841421 8.07128 -0.0841421 7.52833 0.251538 7.19612L7.19362 0.251947C7.5293 -0.0838342 8.07209 -0.0838342 8.4042 0.251947L9.19697 1.04496C9.53622 1.38431 9.52908 1.93798 9.18268 2.27018L4.87959 6.37096H15.1427C15.6177 6.37096 15.9998 6.75317 15.9998 7.22826V8.37134C15.9998 8.84643 15.6177 9.22865 15.1427 9.22865H4.87959L9.18268 13.3294C9.53264 13.6616 9.53978 14.2153 9.19697 14.5546Z"
                          fill="#2B3674"
                        />
                      </svg>
                    </Button>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                      {ReactHtmlParser(t("contact.contactData"))}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Card className="add-asset-form-card" sx={{ p: 2 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={4} lg={3} xl={2}>
                      <ProfileImageDisplayer
                        name={`${contactData.lastName} ${contactData.firstName}`}
                        version="large"
                      />
                    </Grid>
                    <Grid item xs={8} md={8} lg={9} xl={10}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 0, pl: 2 }}
                      >
                        {contactData.lastName} {contactData.firstName}
                        {contactData?.status && (
                          <Box
                            sx={{
                              borderRadius: "3px",
                              display: "inline",
                              /*backgroundColor:
					    contactStatus == "active"
                          ? "#00A86B"
                          : contactStatus == "inactive"
                          ? "#FF4500"
                          : "#FFD800",*/
                              ml: "1rem",
                              p: "3px",
                              pl: "6px",
                              pr: "6px",
                              fontWeight: 400,
                              color: "primary.light",
                            }}
                          >
                            {t(`contact.contactStatus.${contactData.status}`)}
                          </Box>
                        )}
                      </Typography>

                      <Typography
                        variant="body4"
                        sx={{ color: "primary.main", pl: 2 }}
                      >
                        {t(`contact.contactTypes.${contactData.relationType}`)}
                        {((contactData.relationType === "other") && (contactData.customRelationType+"" != "null") &&
                          (<> ({contactData.customRelationType})</>))}
                      </Typography>
                      <Typography
                        variant="body4"
                        sx={{ color: "primary.main", mt: 2, pl: 2 }}
                      >
                        {contactData.email}
                      </Typography>
                      <Typography
                        variant="body4"
                        sx={{ color: "primary.main", pl: 2 }}
                      >
                        {contactData.phone == '+36' ? "" : contactData.phone}
                      </Typography>
                      <Typography
                        variant="body4"
                        sx={{ color: "primary.main", pl: 2, mt: 3 }}
                      >
                        {contactData.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {!chid || chid === "null" ? (
                <>
                  <Card className="add-asset-form-card" sx={{ p: 2, my: 2 }}>
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "20px",
                          mb: 2,
                        }}
                      >
                        {t("contact.access")}
                      </Typography>
                      {!["instant", "timed", "onDeath"].includes(
                        contactData.accessTypeAssets
                      ) &&
                        !["instant", "timed", "onDeath"].includes(
                          contactData.accessTypeDiaryItems
                        ) &&
                        !["instant", "timed", "onDeath"].includes(
                          contactData.accessTypePostMortemRequests
                        ) && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ py: 2 }}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              alignItems="left"
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 700 }}
                              >
                                {ReactHtmlParser(t("contact.noAccessTitle"))}
                              </Typography>
                              <Typography
                                variant="body4"
                                sx={{ color: "primary.main", mt: 0 }}
                              >
                                {t("contact.accesstype.null")}
                              </Typography>
                            </Stack>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                              variant="contained"
                              className="small-btn"
                              onClick={() => {
                                setEditMode(true);
                                setContactData((prev) => ({
                                  ...prev,
                                  accessTypeAssets: "",
                                }));
                              }}
                              sx={{
                                borderRadius: "64px",
                                py: 0,
                                px: 1,
                                bgcolor: "#62C275 !important",
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: 500,
                                "&:hover": {
                                  bgcolor: "#6FD884",
                                  color: "#fff",
                                },
                                "&:focus": {
                                  bgcolor: "#78EF90",
                                  color: "#fff",
                                },
                              }}
                            >
                              {ReactHtmlParser(t("contact.addAccess"))}
                            </Button>
                          </Stack>
                        )}
                      <AccessDisplayRow
                        accessData={contactData.accessTypeAssets}
                        title={t("contact.accessAssets")}
                      />
                      <AccessDisplayRow
                        accessData={contactData.accessTypeDiaryItems}
                        title={t("contact.accessDiaryItems")}
                      />
                      <AccessDisplayRow
                        accessData={contactData.accessTypePostMortemRequests}
                        title={t("contact.accessPostMortemRequests")}
                      />
                    </CardContent>
                  </Card>
                  <Box sx={{ width: "100%" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="end"
                      sx={{ mr: { sm: 0 }, mb: { xs: 6, sm: 0 } }}
                    >
                      <Button
                        variant="contained"
                        className="small-btn"
                        startIcon={<EditIcon sx={{ color: "#2B3674" }} />}
                        sx={{
                          bgcolor: "#FFF !important",
                          color: "#2B3674",
                          boxShadow: "0px 0px 10px 0px #0000001A",
                        }}
                        onClick={() => setEditMode(true)}
                      >
                        {ReactHtmlParser(t("contact.buttondoedit"))}
                      </Button>
                      <Button
                        variant="text"
                        sx={{
                          minWidth: "50px",
                          width: "50px !important",
                          height: "50px",
                          p: 0,
                          boxShadow: "0px 0px 10px 0px #0000001A",
                          borderRadius: "32px",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setGlobalEvent({
                            type: "doDeleteContact",
                            data: { id: contactData.id },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Stack>
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} md={5} sx={{ px: { md: 3 }, mt: 11 }}>
              <Stack direction="column" spacing={2}>
                <HelpCard />
                <ReferralCard />
                <BannerDisplayer position="right" page="asset_page" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          component="form"
          sx={{ display: "flex", justifyContent: { xl: "center" } }}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Grid
            container
            sx={{
              mt: { xs: 0, sm: 8 },
              pl: { xs: 2, md: 27 },
              pr: { xs: 2, md: 17 },
              mb: 20,
              width: { xl: "1530px" },
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
              >
                {ReactHtmlParser(t("contact.addNewContact"))}
              </Typography>
              <Card className="add-asset-form-card" sx={{ p: 2 }}>
                <CardContent>
                  <TextField
                    type="text"
                    required={true}
                    label={ReactHtmlParser(t("field.lastName"))}
                    sx={{
                      mt: 3,
                      width: "100%",
                      "& .MuiInputBase-root": { borderRadius: "50px" },
                    }}
                    {...register("lastName", {
                      required: t("errors.field.required"),
                      maxLength: {
                        value: 64,
                        message: t("errors.field.maxlength"),
                      },
                    })}
                    onChange={(e) =>
                      setContactData((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                    InputProps={{ maxLength: 64 }}
                  />
                  {errors.lastName && (
                    <span role="alert">{errors.lastName.message}</span>
                  )}
                  <TextField
                    required={true}
                    type="text"
                    label={ReactHtmlParser(t("field.firstName"))}
                    sx={{
                      mt: 3,
                      width: "100%",
                      "& .MuiInputBase-root": { borderRadius: "50px" },
                    }}
                    {...register("firstName", {
                      required: t("errors.field.required"),
                      maxLength: {
                        value: 64,
                        message: t("errors.field.maxlength"),
                      },
                    })}
                    onChange={(e) =>
                      setContactData((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                    InputProps={{ maxLength: 64 }}
                  />
                  {errors.firstName && (
                    <span role="alert">{errors.firstName.message}</span>
                  )}

                  <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    sx={{ mt: 3 }}
                  >
                    <Stack direction="column" alignItems="left">
                      <Autocomplete
                        required={true}
                        options={contactTypes}
                        getOptionLabel={(option) =>
                          t(`contact.contactTypes.${option.id}`)
                        }
                        value={
                          contactTypes.find(
                            (option) => option.id === contactData.relationType
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setContactData((prev) => ({
                            ...prev,
                            relationType: newValue ? newValue.id : "",
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...register("relationType", {
                              required: t("errors.field.required"),
                            })}
							required={true}
                            label={ReactHtmlParser(t("contact.contactType"))}
                            sx={{
                              width: 280,
                              borderRadius: "50px",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderRadius: "50px",
                              },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                { borderRadius: "50px" },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                { borderRadius: "50px" },
                            }}
                          />
                        )}
                      />
                      {errors.relationType && (
                        <span role="alert">{errors.relationType.message}</span>
                      )}
                    </Stack>
                    {contactData.relationType === "other" && (
                      <TextField
                        label={ReactHtmlParser(t("contact.other_label"))}
                        {...register("customRelationType", {
                          /*required: t("errors.field.required"),*/
                          maxLength: {
                            value: 64,
                            message: t("errors.field.maxlength"),
                          },
                        })}
                        onChange={(event) => {
                          setContactData((prev) => ({
                            ...prev,
                            customRelationType: event.target.value,
                          }));
                        }}
                        sx={{
                          width: 280,
                          borderRadius: "50px",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderRadius: "50px",
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            { borderRadius: "50px" },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            { borderRadius: "50px" },
                        }}
                      />
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ mt: 3 }}
                  >
                    <Stack direction="column" alignItems="left">
                      <TextField
                        type="email"
                        required={true}
                        label={ReactHtmlParser(t("field.email"))}
                        sx={{
                          width: 280,
                          color: "primary.main",
                          fontWeight: 600,
                          fontFamily: "Sora, sans-serif",
                          fontSize: "14px",
                          lineHeight: "22px",
                          borderRadius: "50px",
                          "& .MuiInputBase-root": { borderRadius: "50px" },
                        }}
                        {...register("email", {
                          required: t("errors.field.required"),
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t("errors.field.emailformat"),
                          },
                        })}
                        onChange={(e) =>
                          setContactData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                      />
                      {errors.email && (
                        <span role="alert">{errors.email.message}</span>
                      )}
                    </Stack>
                  </Stack>
                  <MuiTelInput
                    label={ReactHtmlParser(t("field.phone"))}
                    value={phone}
                    onChange={(val) => {
                      if (phoneBlur)
                        setPhoneIsValid(
                          matchIsValidTel(val.replace(/\s/g, ""))
                        );
                      setPhone(val);
                    }}
                    onBlur={(ev) => {
                      setPhoneBlur(true);
                      setPhoneIsValid(
                        matchIsValidTel(ev.target.value.replace(/\s/g, ""))
                      );
                      checkRequiredFields();
                    }}
                    helperText={
                      !phoneBlur ? (
                        ""
                      ) : matchIsValidTel(phone) ? (
                        ""
                      ) : displayPhoneValidation(phone) ? (
                        <span role="alert">
                          {ReactHtmlParser(t("errors.field.invalidphone"))}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    sx={{
                      mt: 3,
                      width: 280,
                      color: "primary.main",
                      fontWeight: 600,
                      fontFamily: "Sora, sans-serif",
                      fontSize: "14px",
                      lineHeight: "22px",
                      borderRadius: "50px",
                      "& .MuiInputBase-root": {
                        borderRadius: "50px",
                        pl: "26px",
                      },
                    }}
                  />

                  <TextareaAutosize
                    key="comment"
                    {...register("comment")}
                    value={contactData.comment}
                    onChange={(e) =>
                      setContactData((prev) => ({
                        ...prev,
                        comment: e.target.value,
                      }))
                    }
                    placeholder={String(t("contact.comment"))}
                    style={{
                      marginTop: "1.5rem",
                      font: "inherit",
                      width: "100%",
                      height: "133px",
                      resize: "none",
                      border: "1px solid #C6C6C6",
                      borderRadius: "24px",
                      marginLeft: "-10px",
                      marginBottom: "16px",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                      paddingRight: "5px",
                      paddingBottom: "16px",
                    }}
                  />
                </CardContent>
              </Card>
              <Card className="add-asset-form-card" sx={{ px: 0, my: 2 }}>
                <CardContent sx={{ py: 4 }}>
                  <Grid container sx={{ borderRadius: "16px", pt: 2, pb: 2 }}>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        pr: 1,
                        pl: 2,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body3" sx={{ fontWeight: 700 }}>
                        <Checkbox
                          checked={contactData.accessTypeAssets !== null}
                          onChange={() =>
                            setContactData((prev) => ({
                              ...prev,
                              accessTypeAssets:
                                prev.accessTypeAssets === null
                                  ? "instant"
                                  : null,
                            }))
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sx={{
                        display: "flex",
                        pr: 1,
                        pl: 2,
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="body2" sx={{ fontWeight: 700 }}>
                          {ReactHtmlParser(t("contact.accessTypeAssets"))}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  {contactData.accessTypeAssets !== null && (
                    <div>
                      <Grid
                        container
                        sx={{
                          borderRadius: "16px",
                          pt: 2,
                          pb: 2,
                          mt: 2,
                          backgroundColor: "#FAFCFC",
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pr: 1,
                            pl: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body3" sx={{ fontWeight: 700 }}>
                            <Radio
                              checked={
                                contactData.accessTypeAssets === "instant"
                              }
                              onChange={() =>
                                setContactData((prev) => ({
                                  ...prev,
                                  accessTypeAssets: "instant",
                                }))
                              }
                              value="instant"
                              name="radio-buttons"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Stack>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 700 }}
                              >
                                {t("contact.accesstype.instant")}
                              </Typography>
                              <Typography variant="body4" sx={{opacity: 0.6}}>
                                {t("contact.accesstype.instant_text")}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        sx={{
                          borderRadius: "16px",
                          pt: 2,
                          pb: 2,
                          mt: 2,
                          backgroundColor: "#FAFCFC",
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pr: 1,
                            pl: 2,
                          }}
                        >
                          <Typography variant="body3" sx={{ fontWeight: 700 }}>
                            <Radio
                              checked={
                                contactData.accessTypeAssets === "onDeath"
                              }
                              onChange={() =>
                                setContactData((prev) => ({
                                  ...prev,
                                  accessTypeAssets: "onDeath",
                                }))
                              }
                              value="onDeath"
                              name="radio-buttons"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Stack>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 700 }}
                                >
                                  {t("contact.accesstype.ondeath")}
                                </Typography>
                                <Tooltip
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#2B3674",
                                        borderRadius: "8px",
                                      },
                                    },
                                  }}
                                  title={
                                    <Typography
                                      sx={{
                                        color: "#FFFFFF",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {t("contact.lifesignalTooltip")}
                                    </Typography>
                                  }
                                >
                                  <InfoOutlinedIcon
                                    color="primary"
                                    sx={{ width: "16px", height: "16px" }}
                                  />
                                </Tooltip>
                              </Stack>

                              <Typography variant="body4" sx={{opacity: 0.6}}>
                                  {t("contact.accesstype.ondeath_text")}
                                </Typography>
                              <Box sx={{mt: 3}}>
                              {contactData.accessTypeAssets === 'onDeath' && hasLifeSignalContact === false && (
                                <>
                                  <Typography variant="body4" sx={{opacity: 0.6}}>
                                  {t("contact.accesstype.ondeath_desc")}
                                </Typography>
                                  <TextField
                                    select
                                    className="asset-field-currency"
                                    label={t("field.lifeSignal")}
                                    value={lifeSignal}
                                    onChange={handleLifeSignalChange}
                                    sx={{ minWidth: "90%", mt: 2 }}
                                  >
                                    <MenuItem value={"monthly"}>
                                      {t("profile.lifesignal.monthly")}
                                    </MenuItem>
                                    <MenuItem value={"quarterly"}>
                                      {t("profile.lifesignal.quarterly")}
                                    </MenuItem>
                                    <MenuItem value={"semiannually"}>
                                      {t("profile.lifesignal.semiannually")}
                                    </MenuItem>
                                  </TextField>
                                <Stack direction="row" alignItems={"center"} sx={{mt:2, bgcolor: "#CFEDF840", py: "8px", px: "16px", borderRadius: 8, width: "fit-content", maxWidth: "90%"}}>
                                  <EmojiObject sx={{color: "primary.main", width: "16px"}} />
                                  <Typography variant="body4" sx={{fontWeight: 500, fontSize: "10px", lineHeight: "16px", ml: 1}}>
                                    {t("contact.accesstype.ondeath_changeInfo")}
                                  </Typography>
                                </Stack>
                                </>
                              )}

                              {!isLifeSignalEnabled && (
                                <>
                                  <Typography variant="body4">
                                    {t(
                                      "contact.accesstype.ondeath_text_lifesignal"
                                    )}
                                  </Typography>
                                </>
                              )}

                              {!isLifeSignalEnabled &&
                                lifeSignal == "monthly" && (
                                  <>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {t("profile.lifesignal.monthly")}
                                    </Typography>
                                  </>
                                )}
                              {!isLifeSignalEnabled &&
                                lifeSignal == "quarterly" && (
                                  <>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {t("profile.lifesignal.quarterly")}
                                    </Typography>
                                  </>
                                )}
                              {!isLifeSignalEnabled &&
                                lifeSignal == "semiannually" && (
                                  <>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {t("profile.lifesignal.semiannually")}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {contactData.id && (
                    <>
                      <Grid
                        container
                        sx={{ borderRadius: "16px", pt: 2, pb: 2, mt: 2 }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pr: 1,
                            pl: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body3" sx={{ fontWeight: 700 }}>
                            <Checkbox
                              checked={
                                contactData.accessTypeDiaryItems !== null
                              }
                              onChange={() =>
                                setContactData((prev) => ({
                                  ...prev,
                                  accessTypeDiaryItems:
                                    prev.accessTypeDiaryItems
                                      ? null
                                      : "instant",
                                }))
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          sx={{
                            display: "flex",
                            pr: 1,
                            pl: 2,
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 700 }}
                            >
                              {ReactHtmlParser(
                                t("contact.accessTypeDiaryItems")
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      {contactData.accessTypeDiaryItems !== null && (
                        <div>
                          <Grid
                            container
                            sx={{
                              borderRadius: "16px",
                              pt: 2,
                              pb: 2,
                              mt: 2,
                              backgroundColor: "#FAFCFC",
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pr: 1,
                                pl: 2,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body3"
                                sx={{ fontWeight: 700 }}
                              >
                                <Radio
                                  checked={
                                    contactData.accessTypeDiaryItems ===
                                    "instant"
                                  }
                                  onChange={() =>
                                    setContactData((prev) => ({
                                      ...prev,
                                      accessTypeDiaryItems: "instant",
                                    }))
                                  }
                                  value="instant"
                                  name="radio-buttons"
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Stack>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    {t("contact.accesstype.instant")}
                                  </Typography>
                                  <Typography variant="body4">
                                    {t("contact.accesstype.instant_text")}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            sx={{
                              borderRadius: "16px",
                              pt: 2,
                              pb: 2,
                              mt: 2,
                              backgroundColor: "#FAFCFC",
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pr: 1,
                                pl: 2,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body3"
                                sx={{ fontWeight: 700 }}
                              >
                                <Radio
                                  checked={
                                    contactData.accessTypeDiaryItems ===
                                    "onDeath"
                                  }
                                  onChange={() =>
                                    setContactData((prev) => ({
                                      ...prev,
                                      accessTypeDiaryItems: "onDeath",
                                    }))
                                  }
                                  value="onDeath"
                                  name="radio-buttons"
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Stack>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {t("contact.accesstype.ondeath")}
                                    </Typography>
                                    <Tooltip
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            backgroundColor: "#2B3674",
                                            borderRadius: "8px",
                                          },
                                        },
                                      }}
                                      title={
                                        <Typography
                                          sx={{
                                            color: "#FFFFFF",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {t("contact.lifesignalTooltip")}
                                        </Typography>
                                      }
                                    >
                                      <InfoOutlinedIcon
                                        color="primary"
                                        sx={{ width: "16px", height: "16px" }}
                                      />
                                    </Tooltip>
                                  </Stack>
                                  <Typography variant="body4">
                                    {t("contact.accesstype.ondeath_text")}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      <Grid
                        container
                        sx={{ borderRadius: "16px", pt: 2, pb: 2, mt: 2 }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            pr: 1,
                            pl: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body3" sx={{ fontWeight: 700 }}>
                            <Checkbox
                              checked={
                                contactData.accessTypePostMortemRequests !==
                                null
                              }
                              onChange={() =>
                                setContactData((prev) => ({
                                  ...prev,
                                  accessTypePostMortemRequests:
                                    prev.accessTypePostMortemRequests
                                      ? null
                                      : "instant",
                                }))
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          sx={{
                            display: "flex",
                            pr: 1,
                            pl: 2,
                            alignItems: "center",
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 700 }}
                            >
                              {ReactHtmlParser(
                                t("contact.accessTypePostMortemRequests")
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      {contactData.accessTypePostMortemRequests !== null && (
                        <div>
                          <Grid
                            container
                            sx={{
                              borderRadius: "16px",
                              pt: 2,
                              pb: 2,
                              mt: 2,
                              backgroundColor: "#FAFCFC",
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pr: 1,
                                pl: 2,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body3"
                                sx={{ fontWeight: 700 }}
                              >
                                <Radio
                                  checked={
                                    contactData.accessTypePostMortemRequests ===
                                    "instant"
                                  }
                                  onChange={() =>
                                    setContactData((prev) => ({
                                      ...prev,
                                      accessTypePostMortemRequests: "instant",
                                    }))
                                  }
                                  value="instant"
                                  name="radio-buttons"
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Stack>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    {t("contact.accesstype.instant")}
                                  </Typography>
                                  <Typography variant="body4">
                                    {t("contact.accesstype.instant_text")}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            sx={{
                              borderRadius: "16px",
                              pt: 2,
                              pb: 2,
                              mt: 2,
                              mb: 4,
                              backgroundColor: "#FAFCFC",
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pr: 1,
                                pl: 2,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body3"
                                sx={{ fontWeight: 700 }}
                              >
                                <Radio
                                  checked={
                                    contactData.accessTypePostMortemRequests ===
                                    "onDeath"
                                  }
                                  onChange={() =>
                                    setContactData((prev) => ({
                                      ...prev,
                                      accessTypePostMortemRequests: "onDeath",
                                    }))
                                  }
                                  value="onDeath"
                                  name="radio-buttons"
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={11} sx={{ pr: 1, pl: 2 }}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Stack>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {t("contact.accesstype.ondeath")}
                                    </Typography>
                                    <Tooltip
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            backgroundColor: "#2B3674",
                                            borderRadius: "8px",
                                          },
                                        },
                                      }}
                                      title={
                                        <Typography
                                          sx={{
                                            color: "#FFFFFF",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {t("contact.lifesignalTooltip")}
                                        </Typography>
                                      }
                                    >
                                      <InfoOutlinedIcon
                                        color="primary"
                                        sx={{ width: "16px", height: "16px" }}
                                      />
                                    </Tooltip>
                                  </Stack>
                                  <Typography variant="body4">
                                    {t("contact.accesstype.ondeath_text")}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
              <Card className="add-asset-form-card" sx={{ px: 0, my: 2, display:
                        modalFlag && contactData.accessTypeAssets !== null
                          ? "auto"
                          : "none", }}>
                <CardContent sx={{ py: 4 }}>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      px: 2,
                      mb: 4,
                      mt: 2,
                    }}
                    alignItems="center"
                  >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      {t("contact.message")}
                    </Typography>
                    <Typography variant="caption">
                      ({ReactHtmlParser(t("common.optional"))})
                    </Typography>
                  </Stack>
                  <Box sx={{ px: 2 }}>
                    <TextareaAutosize
                      key="description"
                      {...register("message")}
                      value={contactData.message}
                      onChange={(e) =>
                        setContactData((prev) => ({
                          ...prev,
                          message: e.target.value,
                        }))
                      }
                      placeholder={String(
                        t("contact.messagePlaceholder")
                      )}
                      style={{
                        display:
                          modalFlag && contactData.accessTypeAssets !== null
                            ? "block"
                            : "none",
                        font: "inherit",
                        width: "100%",
                        height: "133px",
                        resize: "none",
                        border: "1px solid #C6C6C6",
                        borderRadius: "24px",
                        marginLeft: "-10px",
                        marginBottom: "40px",
                        paddingTop: "16px",
                        paddingLeft: "16px",
                        paddingRight: "5px",
                        paddingBottom: "16px",
                      }}
                    />
                  </Box>
                </CardContent>
                </Card>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    if (contactData.id) {
                      setContactData(contactDataOri);
                      setEditMode(false);
                      window.scrollTo(0, 0);
                    } else {
                      navigate("/contact-list");
                    }
                  }}
                >
                  {ReactHtmlParser(t("common.buttondocancel"))}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isSaveEnabled}
                  className="small-btn secondary-blue-btn"
                  endIcon={
                    <svg
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                        fill="#E5FE4A"
                      />
                    </svg>
                  }
                >
                  {ReactHtmlParser(t("contact.buttondosave"))}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5} sx={{ px: { md: 3 }, mt: 11 }}>
              <Stack direction="column" spacing={2}>
                <HelpCard />
                <ReferralCard />
                <BannerDisplayer position="right" page="asset_page" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </DefaultLayout>
  );
};

export default EditContact;
