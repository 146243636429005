import React, { useRef, props, useEffect, useReducer, useState, Suspense } from "react";
import { Footer } from "../Footer/Footer";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MainMenu } from "../Navigation/MainMenu";
import {
  Typography,
  Grid,
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  InputLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import { MainMobileMenu } from "../Navigation/MainMobileMenu";

import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

import ShowPictureOfAsset from "../FileHandlers/ShowPictureOfAsset";
import ShowFileOfAsset from "../FileHandlers/ShowFileOfAsset";
import AddPictureToAsset from "../FileHandlers/AddPictureToAsset";
import AddFileToAsset from "../FileHandlers/AddFileToAsset";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import DefaultLayout from "../Layout/DefaultLayout";

import currencyJSON from "../Assets/Data/Common-Currency.json";
import currencyMinJSON from "../Assets/Data/Common-Currency-min.json";

import DialogPrompt from "../PopupHandler/DialogPrompt";
import AssetHeader from "../Commons/AssetHeader";
import AssetHeaderBottom from "../Commons/AssetHeaderBottom";
import DateFormatter from "../Commons/DateFormatter";

const EditVehicle = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [chid, setChid] = useState(localStorage.chid);

  const [accountCurrencyMore, setAccountCurrencyMore] = useState(false);
  const [openAccountCurrency, setOpenAccountCurrency] = useState(false);

  const [showDescription, setShowDescription] = useState(false);
  const handleShowDescription = () => {
    setShowDescription(!showDescription);
  };
  const [lastUpdate, setLastUpdate] = useState(null);

  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ", "  ");
  };

  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    doSaveAsset(e, collectData(data, e));
    //console.log(data, e);
  };
  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const [id, setID] = useState(null);
  const [category, setCategory] = useState("Személygépjármű");
  const [draft, setDraft] = useState(true);
  const [name, setName] = useState("");
  const [value, setVal] = useState("");
  const [currency, setCurrency] = useState("");

  const [plateNumber, setPlateNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [buydate, setBuyDate] = useState("");
  const [hasLoan, setHasLoan] = useState(false);
  const [description, setDescription] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [attachmentIds, setAttachmentIds] = useState([]);
  const [attachmentImages, setAttachmentImages] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const [showPersonalData, setShowPersonalData] = useState(false);
  const handleShowPersonalData = () => {
    setShowPersonalData(!showPersonalData);
  };

  const formRef = useRef();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  
    
  useEffect(() => {
    
	if (isValid&&category) {
	  setIsSaveEnabled(true)
	} else {
	  setIsSaveEnabled(false)		
	}    
  }, [isValid,category]);
  
  const [extraCategoryOpen, setExtraCategoryOpen] = useState(false);
  const [extraCategory, setExtraCategory] = useState("");

  const [extraFields, setExtraFields] = useState([]);
  const setExtraField = (extraFieldIndex, extraFieldName, extraFieldValue) => {
    //console.log(extraFields)
    if (!extraFields[extraFieldIndex]) {
      setExtraFields((oldArray) => [
        ...oldArray,
        { name: extraFieldName, value: extraFieldValue },
      ]);
    } else {
      const nextExtraFields = [...extraFields];
      nextExtraFields[extraFieldIndex] = {
        name: extraFieldName,
        value: extraFieldValue,
      };
      setExtraFields(nextExtraFields);
    }
  };

  const deleteExtraField = (extraFieldIndex) => {
    const nextExtraFields = [...extraFields];
    nextExtraFields.splice(extraFieldIndex, 1);
    setExtraFields((oldArray) => [...nextExtraFields]);
  };

  const doSaveAsset = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doSaveAsset", data: data });
  };

  const onFileUpload = (data) => {
	setGlobalEvent({
      type: "checkPlan",
      data: { type: "document", subtype: "vehicle", file: data, fileCount: attachmentFiles.length  },
    });
	
	//setGlobalEvent({
    //  type: "doFileUpload",
    //  data: { file: data, uploadType: "document" },
    //});
  };

  const onImageUpload = (files) => {
    Array.from(files).forEach((file, index) => {
      setTimeout(() => {
	setGlobalEvent({
      type: "checkPlan",
      data: { type: "image", subtype: "vehicle", file: file, fileCount: attachmentImages.length  },
    });
	}, index * 400);
    });
	
	//Array.from(files).forEach((file, index) => {
    //  setTimeout(() => {
    //    setGlobalEvent({
    //      type: "doFileUpload",
    //      data: { file, uploadType: "image" },
    //    });
    //  }, index * 300);
    //});
  };

  const collectData = (data, e) => {
    let collectedData = {};
    collectedData["asset"] = {};

    if (id * 1 > 0) {
      collectedData["asset"]["id"] = id;
    }

    collectedData["asset"]["assetType"] = "vehicle";
    collectedData["asset"]["value"] = data.value ? data.value.replace(/\D/g,'')*1 : 0;
    collectedData["asset"]["currency"] = data.currency;
    //collectedData['asset']['draft'] = draft;

    collectedData["asset"]["details"] = {};
    collectedData["asset"]["details"]["name"] = data.name;
    collectedData["asset"]["details"]["category"] = data.category;
    collectedData["asset"]["details"]["make"] = data.make;
    collectedData["asset"]["details"]["model"] = data.model;
    collectedData["asset"]["details"]["year"] = data.year;
    collectedData["asset"]["details"]["buydate"] = data.buydate;
    collectedData["asset"]["details"]["plateNumber"] = data.plateNumber;
    collectedData["asset"]["details"]["hasLoan"] = hasLoan;
    collectedData["asset"]["details"]["description"] = description;

    collectedData["asset"]["details"]["extraFields"] = extraFields;

    collectedData["attachmentIds"] = attachmentIds;

    return collectedData;
  };

  const setData = (data, e) => {
    setLastUpdate(data?.updatedAt);
    setID(data?.id ? data?.id : null);
    setEditMode(false);
    setVal(data?.value ? formatValue(data?.value*1) : 0);
    setCurrency(data?.currency ? data?.currency : "HUF");

    setName(data?.details?.name ? data?.details?.name : "");
    setCategory(
      data?.details?.category ? data?.details?.category : "Személygépjármű"
    );

    //if (data?.details?.category) {
    //  if (
    //    data?.details?.category != "Személygépjármű" &&
    //    data?.details?.category != "Kishaszon" &&
    //    data?.details?.category != "Motor" &&
    //    data?.details?.category != "Lakókocsi"
    //  ) {
    //    setExtraCategory(data?.details?.category);
    //  }
    //}

    setPlateNumber(
      data?.details?.plateNumber ? data?.details?.plateNumber : ""
    );
    setMake(data?.details?.make ? data?.details?.make : "");
    setModel(data?.details?.model ? data?.details?.model : "");
    setYear(data?.details?.year ? data?.details?.year : "");
    setBuyDate(data?.details?.buydate ? data?.details?.buydate : "");

    if(data?.details?.hasLoan !== undefined) {
      setHasLoan(data?.details?.hasLoan !== false ? true : false);
    }

    setDescription(
      data?.details?.description ? data?.details?.description : ""
    );
    setExtraFields(
      data?.details?.extraFields ? data?.details?.extraFields : []
    );
  };

  useEffect(() => {
    //console.log(state);
    setData(state?.data);
    setEditMode(state?.editmode ? state?.editmode : false);
    if (state?.data?.id) {
      setGlobalEvent({
        type: "doGetAttachments",
        data: { id: state?.data?.id },
      });
    }
  }, [state]);

  useEffect(() => {
    setValue("category", category);
  }, [category]);

  useEffect(() => {
    setValue("name", name);
  }, [name]);

  useEffect(() => {
    setValue("make", make);
  }, [make]);

  useEffect(() => {
    setValue("model", model);
  }, [model]);

  useEffect(() => {
    setValue("plateNumber", plateNumber);
  }, [plateNumber]);

  useEffect(() => {
    setValue("year", year);
  }, [year]);

  useEffect(() => {
    setValue("buydate", buydate);
  }, [buydate]);

  useEffect(() => {
    setValue("value", value);
  }, [value]);

  useEffect(() => {
    setValue("currency", currency);
  }, [currency]);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editMode]);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ EditVehicle");
      //console.log(globalEvent);
      //console.log("------------");
    }


    if (globalEvent?.type == "responseCheckPlan") {
    
	if (globalEvent?.data.subtype  == "vehicle"){
	
	setGlobalEvent({
      type: "doFileUpload",
      data: { file: globalEvent?.data.file, uploadType: globalEvent?.data.type },
    });
	
	}
	
	}

    if (globalEvent?.type == "responseFileUpload") {
      let aryImages = [];
      let aryFiles = [];

      setAttachmentIds([...attachmentIds, globalEvent?.data?.attachment?.id]);

      if (globalEvent?.data.attachment.attachmentType == "image") {
        aryImages.push(globalEvent?.data.attachment);
      } else {
        aryFiles.push(globalEvent?.data.attachment);
      }

      setAttachmentImages([...attachmentImages, ...aryImages]);
      setAttachmentFiles([...attachmentFiles, ...aryFiles]);

      let collectedData = {};
      collectedData["asset"] = {};
      if (id) {
        collectedData["asset"]["id"] = id;
      }
      collectedData["asset"]["assetType"] = "vehicle";
      collectedData["attachmentIds"] = [
        ...attachmentIds,
        globalEvent?.data?.attachment?.id,
      ];

      setGlobalEvent({ type: "doSaveAssetImage", data: collectedData });
    }

	if (globalEvent?.type == "responseSaveAssetImage") {
      setAttachmentIds([]);
	  if (globalEvent?.data?.asset?.id) {
        setID(globalEvent?.data?.asset?.id ? globalEvent?.data?.asset?.id : null);
      }
    }


    if (globalEvent?.type == "responseDeleteAttachment") {
      if (state?.data?.id) {
        setGlobalEvent({
          type: "doGetAttachments",
          data: { id: state?.data?.id },
        });
      }
    }

    if (globalEvent?.type == "responseGetAttachments") {
      //console.log(globalEvent?.data.attachments);
      let aryImages = [];
      let aryFiles = [];

      const length = Array.isArray(globalEvent?.data.attachments)
        ? globalEvent?.data.attachments.length
        : 0;
      for (let i = 0; i < length; i++) {
        if (globalEvent?.data.attachments[i].attachmentType == "image") {
          aryImages.push(globalEvent?.data.attachments[i]);
        } else {
          aryFiles.push(globalEvent?.data.attachments[i]);
        }
      }

      setAttachmentImages([...aryImages]);
      setAttachmentFiles([...aryFiles]);
    }
  }, [globalEvent]);

  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        {editMode !== true && (
          <Box
            className="my-container"
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                
              <AssetHeader
                chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.vehicleData'}  />

              </Grid>
              <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent>
                    <Typography variant="body4" sx={{ color: "primary.main" }}>
                      {plateNumber}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {make} {model} ({year})
                    </Typography>
                    {buydate && (
                      <Typography
                        variant="body4"
                        sx={{ color: "primary.main" }}
                      >
                        {ReactHtmlParser(t("field.buydate"))}: {buydate}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
                <Card
                  className="add-asset-form-card"
                  sx={{ p: 2, width: { xs: "auto", md: "100%" }, my: 2 }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.vehicleDescription"))}
                    </Typography>
                    <Typography
                      variant="body4"
                      sx={{ color: "primary.main", mt: 2 }}
                    >
                      {/*{description}*/}
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  className="add-asset-form-card"
                  sx={{ px: 2, py: 1, width: { xs: "auto", md: "100%" } }}
                >
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        py: 1,
                      },
                    }}
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent={{ xs: "center", sm: "space-between" }}
                      alignItems={{ xs: "space-between", sm: "center" }}
                      sx={{ mb: 0 }}
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 700,
                            mb: 0,
                            color: "primary.light",
                          }}
                        >
                          {ReactHtmlParser(t("asset.actualValue"))}:
                        </Typography>
                        <Typography
                          variant="body4"
                          sx={{ color: "primary.light" }}
                        >
                          {ReactHtmlParser(t("common.refreshed"))}:  <DateFormatter timeStamp={lastUpdate} />
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700 }}
                      >{`${formatValue(value)} ${currency}`}</Typography>
                    </Stack>
                  </CardContent>
                </Card>
				
				<AssetHeaderBottom chid={chid}
                id={id}
                setEditMode={setEditMode}
                setGlobalEvent={setGlobalEvent}
                titleKey={'asset.bankAccountData'}  />								
				
              </Grid>
              <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 10 } }}>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 700, mb: 6, mt: { md: 0, xs: 6 } }}
                  >
                    {ReactHtmlParser(t("asset.vehicle.imagesTitle"))}{" "}
                    {attachmentImages.length > 0 && (
                      <>({attachmentImages.length})</>
                    )}
                  </Typography>
                  <ShowPictureOfAsset
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    itemList={attachmentImages}
                    editMode={editMode}
                  />
                  <Box sx={{ my: 6 }}>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                      {ReactHtmlParser(t("asset.vehicle.documentsTitle"))}{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main" }}
                    >
                      {ReactHtmlParser(t("asset.vehicle.documentsDescription"))}
                    </Typography>
                    {attachmentFiles.length > 0 && (
                      <>({attachmentFiles.length})</>
                    )}
                  </Box>
                  <ShowFileOfAsset
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                    itemList={attachmentFiles}
                    editMode={editMode}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/***** ******/}

        {editMode !== false && (
          <>
            <Box
              ref={formRef}
			  component="form"
              className="my-container"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <Grid
                sx={{
                  mt: { xs: 0, sm: 8 },
                  mb: 20,
                  minHeight: "100vh",
                  textAlign: "left",
                }}
                container
              >
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ mb: 4, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
                  >
                    {ReactHtmlParser(t("asset.vehicleNew"))}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
                  >
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 4 }}
                      >
                        {ReactHtmlParser(t("asset.vehicle.category"))}
                      </Typography>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Button
                          className={
                            "asset-categories-btn " +
                            (category == "Személygépjármű"
                              ? "asset-categories-selected-btn"
                              : "")
                          }
                          variant="contained"
                          onClick={(event) => {
                            setCategory("Személygépjármű");
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.catCar"))}
                        </Button>
                        <Button
                          className={
                            "asset-categories-btn " +
                            (category == "Kishaszon"
                              ? "asset-categories-selected-btn"
                              : "")
                          }
                          variant="contained"
                          onClick={(event) => {
                            setCategory("Kishaszon");
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.catVan"))}
                        </Button>
                        <Button
                          className={
                            "asset-categories-btn " +
                            (category == "Motor"
                              ? "asset-categories-selected-btn"
                              : "")
                          }
                          variant="contained"
                          onClick={(event) => {
                            setCategory("Motor");
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.catMBike"))}
                        </Button>
                        <Button
                          className={
                            "asset-categories-btn " +
                            (category == "Lakókocsi"
                              ? "asset-categories-selected-btn"
                              : "")
                          }
                          variant="contained"
                          onClick={(event) => {
                            setCategory("Lakókocsi");
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.catCaravan"))}
                        </Button>

						<Button
                          className={
                            "asset-categories-btn " +
                            (category == "egyéb"
                              ? "asset-categories-selected-btn"
                              : "")
                          }
                          variant="contained"
                          onClick={(event) => {
                            setCategory("egyéb");
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.morecategory"))}
                        </Button>
                        
                      </Stack>
                      <Box
                        sx={{
                          my: 4,
                          borderTop: "2px solid #EBEBEB",
                          borderBottom: "2px solid #EBEBEB",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 700, mb: 4, mt: 4 }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.vehicleData"))}
                        </Typography>
                        <Stack spacing={2} sx={{ mb: 4 }}>
                          <div Style="display: none">
                            <TextField
                              key="buydate"
                              className="asset-field-small"
                              label={ReactHtmlParser(t("field.buydate"))}
                              type="text"
                              onChange={(event) => {
                                setYear(event.target.value);
                              }}
                              {...register("buydate", {
                                /*required: t("errors.field.required"),
                                ,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.buydate && (
                              <span role="alert">{errors.buydate.message}</span>
                            )}
                          </div>

                          <div>
                            <TextField
                              key="plateNumber"
                              className="asset-field-small"
                              label={ReactHtmlParser(t("field.plateNumber"))}
                              type="text"
                              onChange={(event) => {                                
								event.target.value = event.target.value.toUpperCase();
								setPlateNumber(event.target.value.toUpperCase());
                              }}
                              {...register("plateNumber", {
                                /*required: t("errors.field.required"),*/
							   onChange:(event) => {                                
								event.target.value = event.target.value.toUpperCase();
							   }
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.plateNumber && (
                              <span role="alert">
                                {errors.plateNumber.message}
                              </span>
                            )}
                          </div>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 2, sm: 1 }}
                          >
                            <div>
                              <TextField
                                key="make"
                                className="asset-field-small"
                                label={ReactHtmlParser(t("field.make"))}
                                type="text"
                                required={true}
                                onChange={(event) => {
                                  setMake(event.target.value);
                                }}
                                {...register("make", {
                                  required: t("errors.field.required"),
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              />

                              {errors.make && (
                                <span role="alert">{errors.make.message}</span>
                              )}
                            </div>
                            <TextField
                              key="model"
                              className="asset-field-large"
                              label={ReactHtmlParser(t("field.model"))}
                              type="text"
                              onChange={(event) => {
                                setModel(event.target.value);
                              }}
                              {...register("model", {
                                /*required: t("errors.field.required"),
                                ,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.model && (
                              <span role="alert">{errors.model.message}</span>
                            )}
                          </Stack>

                          <div>
                            <TextField
                              key="year"
                              className="asset-field-small"
                              label={ReactHtmlParser(t("field.year"))}
                              type="text"
                                required={true}
                              onChange={(event) => {
                                setYear(event.target.value);
                              }}
                              {...register("year", {
                                required: t("errors.field.required"),
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            />

                            {errors.year && (
                              <span role="alert">{errors.year.message}</span>
                            )}
                          </div>
                          <Stack
                            spacing={2}
                            direction={{ xs: "column", sm: "row" }}
                          >
                            <div>
                              <TextField
                                key="value"
                                className="asset-field-small"
                                label={ReactHtmlParser(t("field.value"))}
                                type="text"
                                required={false}
                                onFocus={(event) => {
                                event.target.select();
								}}
								onChange={(event) => {
                                  setVal(event.target.value);
                                }}
                                {...register("value", {
                                  required: t("errors.field.required"),
		  onChange: (event) => {
			  //console.log(formatValue(event.target.value.replace(/\D/g,'')*1)); 
			  event.target.value = formatValue(event.target.value.replace(/\D/g,'')*1)
			  }
                                  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                                })}
                              />

                              {errors.value && (
                                <span role="alert">{errors.value.message}</span>
                              )}
                            </div>

                            <TextField
                              select
							  closeMenuOnSelect={false}
                              blurInputOnSelect={false}
                              key="currency"
                              className="asset-field-currency"
                              label={ReactHtmlParser(t("field.currency"))}
                              type="text"
                              defaultValue={currency}
                              required={false}
							  onChange={(ev) => {
                              ev.preventDefault();
                              if (ev.target.value == "MORE") {
                                setAccountCurrencyMore(true);

                                setTimeout(() => {
                                  setOpenAccountCurrency(true);
                                }, 1000);
                              } else {
                                setValue("currency", ev.target.value);
                              }
                            }}
                            inputProps={{
                              open: openAccountCurrency,
                              onClose: (ev) => {
                                //console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(false);
                                return false;
                              },
                              onOpen: (ev) => {
                                //console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(true);
                                return false;
                              },
                            }}
                              {...register("currency", {
                                required: t("errors.field.required"),
								onChange: (ev) => {
                                if (ev.target.value == "MORE") {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setAccountCurrencyMore(true);
                                  setTimeout(() => {
                                    setOpenAccountCurrency(true);
                                  }, 250);
                                  return false;
                                } else {
                                  setCurrency(ev.target.value);
                                  setValue("currency", ev.target.value);
                                }
                              },
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                            >
							  {!accountCurrencyMore && (							  
							  <MenuItem
                                value={currency}
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
							  {currency}
                              </MenuItem>
                            )}
                            {Object.keys(currencyMinJSON).map((key, index) => {
                              return (
                                !accountCurrencyMore && (currencyJSON[key].code != currency) && (
                                  <MenuItem
                                    closeOnSelect={false}
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                            {!accountCurrencyMore && (
                              <MenuItem
                                value="MORE"
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
                                {t("common.moreCurrency")}
                              </MenuItem>
                            )}

                            {Object.keys(currencyJSON).map((key, index) => {
                              return (
                                accountCurrencyMore && (
                                  <MenuItem
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                            </TextField>

                            {errors.currency && (
                              <span role="alert">
                                {errors.currency.message}
                              </span>
                            )}
                          </Stack>


                          <TextareaAutosize
                            key="description"
                            placeholder={ReactHtmlParser(
                              t("asset.vehicle.descriptionPlaceholder")
                            )}
                            {...register("description", {
                              /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                            })}
                            value={description}
                            onChange={(event) => {
                              setDescription(event.target.value);
                            }}
                            style={{
                              font: 'inherit',
                              font: 'inherit',
                              width: "100%",
                              height: "133px",
                              resize: "none",
                              border: "1px solid #C6C6C6",
                              borderRadius: "24px",
                              marginTop: "20px",
                              marginLeft: "-10px",
                              paddingTop: "16px",
                              paddingLeft: "16px",
                              paddingRight: "5px",
                              paddingBottom: "16px",
                            }}
                          />

                        </Stack>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ mt: 4, alignItems: "center" }}
                      >
                        <Checkbox
                          checked={hasLoan}
                          onChange={(event, val) => {
                            if (val) {
                              setHasLoan(true);
                            } else {
                              setHasLoan(false);
                            }
                          }}
                        />
                        <Typography
                          variant="body3"
                          sx={{
                            fontSize: "14px",
                            color: "#000000",
                            lineHeight: "17.64px",
                          }}
                        >
                          {ReactHtmlParser(t("asset.vehicle.hasLoan"))}
                        </Typography>

                      </Stack>


                    </CardContent>
                  </Card>

                  <Card
                    className="add-asset-form-card"
                    sx={{ p: 2, width: { xs: "auto", md: "100%" }, mt: 2., display: 'none' }}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          py: 1,
                        },
                      }}
                    >
                      <Stack
                        onClick={handleShowDescription}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        sx={{ pb: 0, cursor: 'pointer' }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"baseline"}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              mb: 0,
                              opacity: !showDescription ? 0.5 : 1,
                            }}
                          >
                            {ReactHtmlParser(t("asset.vehicleDescription"))}
                          </Typography>
                          <Typography variant="caption">
                            ({ReactHtmlParser(t("common.optional"))})
                          </Typography>
                        </Stack>
                        <Box
                          variant="text"
                          onClick={handleShowDescription}
                          sx={{
                            mt: 0.7,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: !showDescription
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                        >
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.5 20L16 12L24.5 20" stroke="#2B3674" />
                          </svg>
                        </Box>
                      </Stack>
                      {showDescription ? (
                        <>
                          <TextareaAutosize
                            key="description"
                            placeholder={ReactHtmlParser(
                              t("asset.vehicle.descriptionPlaceholder")
                            )}
                            {...register("description", {
                              /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                            })}
                            value={description}
                            onChange={(event) => {
                              setDescription(event.target.value);
                            }}
                            style={{
                              font: 'inherit',
                              font: 'inherit',
                              width: "100%",
                              height: "133px",
                              resize: "none",
                              border: "1px solid #C6C6C6",
                              borderRadius: "24px",
                              marginTop: "20px",
                              marginLeft: "-10px",
                              paddingTop: "16px",
                              paddingLeft: "16px",
                              paddingRight: "5px",
                              paddingBottom: "16px",
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>

                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
                  >
                    <Button
                      variant="text"
                      className="secondary-blue-text-btn"
                      onClick={(e) => {
                        if (!id) {
                          navigate("/asset-list");
                        } else {
                          setEditMode(false);
                        }
                      }}
                    >
                      {ReactHtmlParser(t("common.buttondocancel"))}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      className="small-btn secondary-blue-btn"
                      disabled={!isSaveEnabled}
                      onClick={(ev) => {
                        formRef.current.requestSubmit();
                      }}
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                      {ReactHtmlParser(t("asset.vehicleSave"))}
                    </Button>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{ pl: { xs: 0, md: 10 }, mt: { md: 0, xs: 6 } }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 700, mb: 6 }}>
                      {ReactHtmlParser(t("asset.vehicle.imagesTitle"))}{" "}
                      {attachmentImages.length > 0 && (
                        <>({attachmentImages.length})</>
                      )}
                    </Typography>
                    <AddPictureToAsset onFileUpload={onImageUpload} />
                    <Box sx={{ mt: 2 }}>
                      <ShowPictureOfAsset
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                        itemList={attachmentImages}
                        editMode={editMode}
                      />
                    </Box>
                    <Box sx={{ my: 6 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, mb: 0 }}
                      >
                        {ReactHtmlParser(t("asset.vehicle.documentsTitle"))}{" "}
                        {attachmentFiles.length > 0 && (
                          <>({attachmentFiles.length})</>
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "primary.main" }}
                      >
                        {ReactHtmlParser(
                          t("asset.vehicle.documentsDescription")
                        )}
                      </Typography>
                    </Box>
                    <AddFileToAsset onFileUpload={onFileUpload} />
                    <Box sx={{ mt: 2 }}>
                      <ShowFileOfAsset
                        globalEvent={globalEvent}
                        setGlobalEvent={setGlobalEvent}
                        itemList={attachmentFiles}
                        editMode={editMode}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </DefaultLayout>
      <DialogPrompt
        cancelLabel={ReactHtmlParser(t("common.cancel"))}
        selectLabel={ReactHtmlParser(t("common.select"))}
        dialogTitle={ReactHtmlParser(t("asset.realEstate.addCategoryTitle"))}
        dialogText={ReactHtmlParser(
          t("asset.realEstate.addCategoryDescription")
        )}
        defaultValue={extraCategory}
        isOpen={extraCategoryOpen}
        onSelectValue={(val) => {
          if (val) {
            setCategory(val);
            setExtraCategory(val);
          }
        }}
        onClose={() => setExtraCategoryOpen(false)}
      />
    </>
  );
};

export default EditVehicle;
