import { t } from "i18next";
import { fetchWithAuth } from "../api";
import exchangeVal from "../../Components/Commons/CurrencyExchange";
import { getTypeOrder } from "../Reducers/assetReducer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import CandlestickIcon from "@mui/icons-material/CandlestickChartOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShareIcon from "../../Components/Commons/Icons/ShareIcon";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import BitcoinIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import HeartIcon from "../../Components/Commons/Icons/HeartIcon";
import CreditIcon from "../../Components/Commons/Icons/CreditIcon";
import OtherAssetIcon from "../../Components/Commons/Icons/OtherAssetIcon";

const iconMap = {
  DirectionsCarIcon,
  CandlestickIcon,
  CreditCardIcon,
  ShareIcon,
  HomeWorkIcon,
  AccountBalanceIcon,
  BitcoinIcon,
  HeartIcon,
  CreditIcon,
  OtherAssetIcon,
};

export const getIconComponent = (iconName) => {
  return iconMap[iconName] || null;
};

export const fetchAssets = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_ASSETS_REQUEST" });
    try {
      const data = await fetchWithAuth("assets");
      dispatch({ type: "FETCH_ASSETS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_ASSETS_FAILURE", payload: error });
    }
  };
};

export const prepareAssetCards = (items) => {
  const vCards = [];
  const assetCounts = {
    realEstate: 0,
    bankAccount: 0,
    credit: 0,
    housingSavings: 0,
    insurance: 0,
    security: 0,
    share: 0,
    vehicle: 0,
    claim: 0,
    crypto: 0,
    other: 0,
  };

  const sumsLocal = {
    realEstate: 0,
    bankAccount: 0,
    credit: 0,
    housingSavings: 0,
    insurance: 0,
    security: 0,
    share: 0,
    vehicle: 0,
    claim: 0,
    crypto: 0,
    other: 0,
    total: 0,
  };

  const bCurrency = localStorage.getItem("baseCurrency")?.toLowerCase() || "huf";

  const updateSum = (type, val) => {
    
	sumsLocal[type] = (sumsLocal[type] || 0) + val;
    sumsLocal.total = (sumsLocal.total || 0) + val;
  };

  items.forEach((item) => {
    const type = item.assetType;
    let val = !isNaN(item.value * 1) ? item.value * 1 : 0;

    if (type === "credit") {
      val = val > 0 ? val * -1 : val;
    }

    if (item.currency?.toLowerCase() === bCurrency) {
      updateSum(type, val);
    } else {
      val = exchangeVal(val, item.currency?.toLowerCase() || "huf", "usd");
      val = Math.ceil(exchangeVal(val, "usd", bCurrency) * 1);
      updateSum(type, val);
    }

    assetCounts[type] += 1;

    const cardIndex = vCards.findIndex((card) => card.type === type);
    if (cardIndex === -1) {
      vCards.push({
        key: vCards.length,
        type: type,
        vtype: "valuable",
        icon: type,
        title: t(`asset-type.${type}`) + ` (${assetCounts[type]})`,
        info: sumsLocal[type],
        currency: "usd",
        linTo: "/asset-list",
        linToData: { filterType: type },
      });
    } else {
      vCards[cardIndex].title = t(`asset-type.${type}`) + ` (${assetCounts[type]})`;
	  vCards[cardIndex].info = sumsLocal[type];
    }
  });

  const typeOrder = getTypeOrder();
  const sortedVCards = vCards.sort((a, b) => typeOrder[a.type] - typeOrder[b.type]);

  return {
    type: "PREPARE_ASSET_CARDS",
    payload: sortedVCards,
  };
};
