import React, { useState, useCallback, useMemo } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import UserCard from "../Shared/Cards/UserCard";
import { t } from "i18next";
import WhoSeesForm from "./WhoSeesForm";
import { useDispatch } from "react-redux";
import { fetchContacts, saveContact } from "../../store/Actions/contactAction";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";

const WhoSees = ({ globalEvent, setGlobalEvent, users, suffix }) => {
  const [editUserId, setEditUserId] = useState(null);
  const [addUserId, setAddUserId] = useState(null);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempData, setTempData] = useState(null);
  const [showEditBtn, setShowEditBtn] = useState(true);

  const showForm = useMemo(
    () => editUserId || addUserId,
    [editUserId, addUserId]
  );

  const addUser = useCallback((user) => {
    setAddUserId(user.id);
    setEditUserId(null);
  }, []);

  const editUser = useCallback((user) => {
    setEditUserId(user.id);
    setAddUserId(null);
  }, []);

  const handleCancel = useCallback(() => {
    setShowEditBtn(true);
    setAddUserId(null);
    setEditUserId(null);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    let data = tempData;
    let collectedData = {
      contact: {
        id: editUserId ? editUserId * 1 : addUserId * 1,
		firstName: data.user.firstName,
        lastName: data.user.lastName,
		relationType: data.user.relationType,
        [`accessType${suffix}`]:
          data.reservationType === "instant"
            ? "instant"
            : data.reservationType === "onRequest"
            ? "onDeath"
            : data.reservationType === "specificTime"
            ? "timed"
            : null,
        ...(data.reservationType === "specificTime" && {
          [`accessAt${suffix}`]: data.selectedDate,
        }),
      },
    };

    dispatch(saveContact(collectedData));

    setAddUserId(null);
    setEditUserId(null);
    setIsModalOpen(false);
    setTempData(null);
  };

  const handleSaveForm = (data) => {
    setShowEditBtn(true);
	setTempData(data);
    setIsModalOpen(true);
  };

  const renderUserCard = (user) => (
    <UserCard
      data={user}
      key={user.id}
      name={user.name}
      date={user.date}
      type={user.type}
      actionButtonText={t("timeCapsule.whoSees.btnAdd")}
      editButtonText={t("timeCapsule.whoSees.btnEdit")}
      onAction={!user.added ? () => addUser(user) : undefined}
      onEdit={
        user.added
          ? () => {
              editUser(user);
              setShowEditBtn(false);
            }
          : undefined
      }
      showEditBtn={showEditBtn}
    />
  );

  const renderForm = (user) => (
    <React.Fragment key={`user-form-${user.id}`}>
      {renderUserCard(user)}
      <WhoSeesForm
        onSave={handleSaveForm}
        onCancel={handleCancel}
        type={user.type}
		user={user}
      />
    </React.Fragment>
  );

  return (
    <>
      <Card
        sx={{
          minWidth: 275,
          boxShadow: "none",
          borderRadius: "23px",
          border: "1px solid",
          borderColor: "#2B36741A",
        }}
      >
        <CardContent sx={{ p: 4 }}>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#2B3674",
              lineHeight: "23px",
              fontWeight: 700,
            }}
            color="text.secondary"
            gutterBottom
          >
            {t("timeCapsule.whoSees.title")}
          </Typography>

          {users.map((user) =>
            showForm && (addUserId === user.id || editUserId === user.id)
              ? renderForm(user)
              : renderUserCard(user)
          )}
        </CardContent>
      </Card>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={t("timeCapsule.whoSees.modal.title")}
        description={t("timeCapsule.whoSees.modal.description")}
        cancelText={t("timeCapsule.whoSees.modal.cancel")}
        confirmText={t("timeCapsule.whoSees.modal.confirm")}
        confirmIconColor="#E5FE4A"
        onConfirm={handleConfirm}
        icon={<HandGestureIcon />}
      />
    </>
  );
};

export default WhoSees;
