import {
  Typography,
  Grid,
  Box,
  Stack,
  Card,
  CardContent,
  Button,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import { fetchContacts } from "../../store/Actions/contactAction";
import { saveEntry } from "../../store/Actions/postMortemActions";
import { useNavigate } from "react-router-dom";

export const PostMortemEntryForm = ({
  isNewEntry,
  entryData,
  onCancel,
  onSubmitSuccess,
  globalEvent,
  setGlobalEvent,
}) => {
  const dispatch = useDispatch();
  const [entryId, setEntryId] = useState(entryData?.id ?? null);
  const [title, setTitle] = useState(entryData?.title ?? "");
  const [content, setContent] = useState(entryData?.text ?? "");
  const [contactId, setContactId] = useState(entryData?.contactId ?? null);
  const {items, loading, error } = useSelector((state) => state.contact ?? []);
  const [hasPlan, setHasPlan] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    
	//console.log(globalEvent);

    if (globalEvent?.type == "responseCheckPlan") {
      if (globalEvent?.data.type == "postMortem") {
	   setHasPlan(true);
      }
    }

    if (globalEvent?.type == "responseCheckPlanError") {
      if (globalEvent?.data.type == "postMortem") {
	   setHasPlan(false);
	   setTitle('');
      }
    }	
	
  }, [globalEvent]);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const titleValue = watch("title");
  useEffect(() => {
    setTitle(titleValue || "");
    if (isNewEntry) {
      if (setGlobalEvent) {
        if (titleValue?.length > 0)
          setGlobalEvent({ type: "checkPlan", data: { type: "postMortem" } });
      }
    }
  }, [titleValue]);

  const onSubmit = (data) => {
    dispatch(
      saveEntry(
        entryId,
        {
          title: data.title,
          text: data.content,
        },
        navigate
      )
    );

    if (onSubmitSuccess) {
      onSubmitSuccess(data);
    }

    handleReset();
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const handleReset = () => {
    reset();
    setHasPlan(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {isNewEntry
                    ? t("postMortemRequest.newEntry.title")
                    : t("postMortemRequest.updateEntry.title")}
                </Typography>

                <Box
                  sx={{ mt: 1 }}
                  component="form"
                  onSubmit={handleSubmit(onSubmit, onError)}
                >
                  <TextField
                    label={t("postMortemRequest.newEntry.titlePlaceholder")}
                    className="fullwidth-field"
                    {...register("title", { required: true })}
                    defaultValue={title}
                    error={!!errors.title}
                    sx={{
                      marginTop: "15px !important",
                    }}
					onFocus={()=>{ if (isNewEntry) {setGlobalEvent({ type: "checkPlan", data: { type: "postMortem" } });}}}
					inputProps={
							{ readOnly: hasPlan ? false : true }
					}					
                  />

                  {title.length > 0 && (hasPlan||!isNewEntry) && (
                    <>
                      <Fade in={true} timeout={300}>
                        <TextField
                          placeholder={t(
                            "postMortemRequest.newEntry.descriptionPlaceholder"
                          )}
                          className="fullwidth-textarea"
                          {...register("content", { required: true })}
                          error={!!errors.content}
                          multiline
                          rows={4}
                          defaultValue={content}
                          sx={{
                            marginTop: "15px !important",
							
                          }}						  
                        />
                      </Fade>
                    </>
                  )}

                  {title.length > 0 && (hasPlan||!isNewEntry) && (
                    <>
                      <Fade in={title.length > 0} timeout={300}>
                        <Stack
                          direction="row"
                          sx={{ width: "100%", alignItems: "center", mt: 3 }}
                        >
                          <Button
                            variant="text"
                            className="secondary-blue-text-btn"
                            sx={{
                              height: "50px",
                              fontSize: 12,
                            }}
                            onClick={handleReset}
                          >
                            {t("postMortemRequest.newEntry.cancelBtn")}
                          </Button>

                          <Box sx={{ flexGrow: 1 }} />

                          <Button
                            className="secondary-blue-btn"
                            variant="contained"
                            type="submit"
                            sx={{
                              height: "52px",
                              width: "auto !important",
                              minWidth: "auto !important",
                            }}
                          >
                            {t("postMortemRequest.newEntry.submitBtn")}
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "inherit",
                                strokeWidth: "1",
                                ml: 3,
                              }}
                            />
                          </Button>
                        </Stack>
                      </Fade>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default PostMortemEntryForm;
